import React from "react";

export default function Portfolio(props){
    function touchStarted(event){
        const startY = event.touches[0].clientY
        const portfolio = document.getElementById("portfolio")
        portfolio.addEventListener("touchend", touchEnd)
        function touchEnd(event){
            const endY = event.changedTouches[0].clientY
            if(endY > startY + 200){
                // props.swipeUp()
                return
            } else if(endY < startY - 200) {
                // props.swipeDown()
                return
            }
        }
    }

    const projectsAllX = [    
        {
            type: "htmlcss",
            typex: "html & css",
            name:"dictionxry",
            description:"dictionary app",
            techused:[
                {name: "javascript"},
                {name: "api"},
            ],
            livesite:"https://dictionxry.netlify.app",
            githublink:"https://github.com/bytegen-dev",
            imagesrc:"dictionxry",
        },
        
        {
            type: "htmlcss",
            typex: "html & css",
            name:"artlantis",
            description:"music streaming",
            techused:[
                {name: "html"},
                {name: "css"},
                {name: "javascript"},
            ],
            livesite:"https://artlantis.netlify.app",
            githublink:"https://github.com/bytegen-dev",
            imagesrc:"artlantis",
        },
        {
            type: "htmlcss",
            typex: "html & css",
            name:"ignition labz",
            description:"Digital marketing landing page",
            techused:[
                {name: "html"},
                {name: "css"},
                {name: "javascript"},
            ],
            livesite:"https://ignition-labz.netlify.app",
            githublink:"https://github.com/bytegen-dev",
            imagesrc:"ignition-labz",
        },
        
        {
            type: "htmlcss",
            typex: "html & css",
            name:"mastercxrd",
            description:"e-commerce landing-page",
            techused:[
                {name: "html"},
                {name: "css"},
                {name: "javascript"},
            ],
            livesite:"https://mastercxrd.netlify.app",
            githublink:"https://github.com/bytegen-dev",
            imagesrc:"mastercxrd",
        },
        {
            type: "react",
            typex: "react js",
            name:"xoxo",
            description:"xtra ordinary",
            techused:[
                {name: "react js"},
                {name: "css"},
                {name: "jsx"},
            ],
            livesite:"https://xoxotm.netlify.app",
            githublink:"https://github.com/bytegen-dev",
            imagesrc:"xoxotm",
        },
        {
            type: "react",
            typex: "react js",
            name:"meme maker app",
            description:"meme maker app",
            techused:[
                {name: "api"},
                {name: "react"},
                {name: "css"},
            ],
            livesite:"https://isaac-meme-generator.netlify.app",
            githublink:"https://github.com/bytegen-dev",
            imagesrc:"meme-generator",
        },
        {
            type: "react",
            typex: "react js",
            name:"ByteGen RPS",
            description:"rock paper scissors",
            techused:[
                {name: "react"},
                {name: "sass"},
                {name: "jsx"},
            ],
            livesite:"https://tertiux-rps.netlify.app",
            githublink:"https://github.com/bytegen-dev",
            imagesrc:"tertiux-rps",
        },
        {
            type: "react",
            typex: "react js",
            name:"xo-notes app",
            description:"modern notes app",
            techused:[
                {name: "react"},
                {name: "css"},
            ],
            livesite:"https://xo-notes.netlify.app",
            githublink:"https://github.com/bytegen-dev",
            imagesrc:"xo-notes",
        },
        {
            type: "htmlcss",
            typex: "html & css",
            name:"antic",
            description:"antic furnitures",
            techused:[
                {name: "html"},
                {name: "css"},
                {name: "javascript"},
            ],
            livesite:"https://my-antic.netlify.app",
            githublink:"https://github.com/bytegen-dev",
            imagesrc:"antic",
        },
        {
            type: "htmlcss",
            typex: "html & css",
            name:"foodplug",
            description:"foodplug restaurant",
            techused:[
                {name: "html"},
                {name: "css"},
                {name: "javascript"},
            ],
            livesite:"https://weluvfood.netlify.app",
            githublink:"https://github.com/bytegen-dev",
            imagesrc:"weluvfood",
        },
        {
            type: "react",
            typex: "react js",
            name:"Idan.TV",
            description:"Comedy News outlet",
            techused:[
                {name: "react js"},
                {name: "sass"},
                {name: "router v6"},
            ],
            livesite:"https://idan-tv.netlify.app",
            githublink:"https://github.com/bytegen-dev",
            imagesrc:"idan-tv",
        },
        {
            type: "react",
            typex: "react js",
            name:"Weather app",
            description:"Weather app",
            techused:[
                {name: "api"},
                {name: "react js"},
                {name: "css"},
                {name: "jsx"},
            ],
            livesite:"https://xo-weather.netlify.app",
            githublink:"https://github.com/bytegen-dev",
            imagesrc:"xo-weather",
        },
        {
            type: "react",
            typex: "react js",
            name:"Amazon SKU Genator",
            description:"Amazon SKU Generat...",
            techused:[
                {name: "react js"},
                {name: "amazon sp api"},
                {name: "react-pdf"},
            ],
            livesite:"https://github.com/bytegen-dev/amazon-sp-api",
            githublink:"https://github.com/bytegen-dev/amazon-sp-api",
            imagesrc:"amazon-sku",
        },
        {
            type: "react",
            typex: "react js",
            name:"contacts",
            description:"Contacts web app",
            techused:[
                {name: "react"},
                {name: "css"},
            ],
            livesite:"https://xo-contacts.netlify.app",
            githublink:"https://github.com/bytegen-dev",
            imagesrc:"xo-contacts",
        },
        {
            type: "react",
            typex: "react js",
            name:"Soccer Quiz Hub",
            description:"Trivia quiz app",
            techused:[
                {name: "react"},
                {name: "router v6"},
                {name: "PWA"},
            ],
            livesite:"https://soccer-quiz-hub.netlify.app",
            githublink:"https://github.com/bytegen-dev",
            imagesrc:"soccerquiz",
        },
        {
            type: "react",
            typex: "react js",
            name:"Minions game",
            description:"Minions kids game 💜",
            techused:[
                {name: "react"},
                {name: "css"},
                {name: "game"},
            ],
            livesite:"https://xo-minions-game.netlify.app",
            githublink:"https://github.com/bytegen-dev",
            imagesrc:"minions-game",
        },
        {
            type: "react",
            typex: "react js",
            name:"Trek Todo_",
            description:"todo web app",
            techused:[
                {name: "crud"},
                {name: "react"},
                {name: "scss"},
            ],
            livesite:"https://trek-todo.netlify.app",
            githublink:"https://github.com/bytegen-dev",
            imagesrc:"trek-todo",
        },
        {
            type: "react",
            typex: "react js",
            name:"Sound Bridge",
            description:"download songs from spotify...",
            techused:[
                {name: "api"},
                {name: "react"},
                {name: "sass"},
            ],
            livesite:"https://spotify-getter.netlify.app",
            githublink:"https://github.com/bytegen-dev",
            imagesrc:"sound-bridge",
        },
        {
            type: "uiux",
            typex: "uiux",
            name:"VitaGuard UI",
            description:"UI design",
            techused:[
                {name: "figma"},
            ],
            livesite:"https://www.figma.com/file/hmqW1WI2hVOpOxvJbG3YST/VitaGuard?type=design&node-id=0-1&mode=design&t=tRDFAxlSp4fNqit7-0",
            githublink:"https://github.com/bytegen-dev",
            imagesrc:"vitaguard",
        },
        {
            type: "web3",
            typex: "React",
            name:"DEEPSOUTH AI",
            description:"DeepSouth AI 3D site",
            techused:[
                {name: "react"},
                {name: "three.js"},
                {name: "javascript"},
            ],
            livesite:"https://deepsouth-ai-3d-website.vercel.app/",
            githublink:"https://github.com/bytegen-dev",
            imagesrc:"deep-south",
        },
        {
            type: "react",
            typex: "React",
            name:"Shadow Fall",
            description:"ShadowFall Game Website",
            techused:[
                {name: "react"},
                {name: "figma"},
            ],
            livesite:"https://futuristic-game-landing-page.vercel.app/",
            githublink:"https://github.com/bytegen-dev",
            imagesrc:"shadow-fall",
        },
        {
            type: "react",
            typex: "React",
            name:"Explore Three.js",
            description:"Interactive Three.js",
            techused:[
                {name: "react"},
                {name: "three.js"},
                {name: "javascript"},
            ],
            livesite:"https://isaac-three-js-dev.vercel.app/",
            githublink:"https://github.com/bytegen-dev",
            imagesrc:"threejs",
        },
        {
            type: "react",
            typex: "React",
            name:"WGames",
            description:"WGames portfolio",
            techused:[
                {name: "react"},
                {name: "scss"},
                {name: "firebase"},
            ],
            livesite:"https://wgames-template.vercel.app",
            githublink:"https://github.com/bytegen-dev",
            imagesrc:"wgames",
        },
        {
            type: "react",
            typex: "OpenAI API",
            name:"AI image gen...",
            description:"AI image generator",
            techused:[
                {name: "react"},
                {name: "DALL-E"},
                {name: "javascript"},
            ],
            livesite:"https://ai-image-genx.netlify.app",
            githublink:"https://github.com/bytegen-dev",
            imagesrc:"ai-image",
        },
        {
            type: "react",
            typex: "Typescript",
            name:"Meme gen",
            description:"Meme generator 2.0",
            techused:[
                {name: "next.js"},
                {name: "tailwind"},
                {name: "typescript"},
            ],
            livesite:"https://meme-gen-green.vercel.app",
            githublink:"https://github.com/bytegen-dev",
            imagesrc:"meme-gen",
        },
        {
            type: "web3",
            typex: "Memecoin",
            name:"$SIK",
            description:"memecoin website",
            techused:[
                {name: "framer"},
                {name: "react"},
                {name: "scss"}
            ],
            livesite:"https://solana-is-king.vercel.app",
            githublink:"https://github.com/bytegen-dev",
            imagesrc:"sik",
        },
        {
            type: "web3",
            typex: "Memecoin",
            name:"$SBALL",
            description:"memecoin website",
            techused:[
                {name: "framer"},
                {name: "react"},
                {name: "scss"}
            ],
            livesite:"https://snowballcoin.vercel.app",
            githublink:"https://github.com/bytegen-dev",
            imagesrc:"sball",
        },
        {
            type: "web3",
            typex: "Cardano",
            name:"cnftshirt.io",
            description:"cnft print shop",
            techused:[
                {name: "Next.js"},
                {name: "Typescript"},
                {name: "cardano"},
            ],
            livesite:"https://cnftshirt.io",
            githublink:"https://github.com/bytegen-dev",
            imagesrc:"cnftshirt",
        },
        {
            type: "react",
            typex: "Code Editor",
            name:"CodE",
            description:"basic code editor ;)",
            techused:[
                {name: "next.js"},
                {name: "typescript"},
                {name: "iframe"},
            ],
            livesite:"https://bytegen-code.vercel.app",
            githublink:"https://github.com/bytegen-dev/html-code-editor.git",
            imagesrc:"code",
        },
        {
            type: "web3",
            typex: "solana",
            name:"SolRain",
            description:"Airdrop tasks",
            techused:[
                {name: "next.js"},
                {name: "solana"},
                {name: "web3.js"},
            ],
            livesite:"https://solrain.vercel.app",
            githublink:"https://github.com/bytegen-dev",
            imagesrc:"solrain",
        },
        {
            type: "web3",
            typex: "Ethereum",
            name:"Eth chat",
            description:"Social dapp",
            techused:[
                {name: "react"},
                {name: "web3.js"},
                {name: "walletconnect"},
            ],
            livesite:"https://ether-chat-xi.vercel.app",
            githublink:"https://github.com/bytegen-dev",
            imagesrc:"ether-chat",
        },
        {
            type: "web3",
            typex: "Cardano",
            name:"Spacelabz",
            description:"NFT Wardrobe",
            techused:[
                {name: "Next.js"},
                {name: "Typescript"},
                {name: "cardano"},
            ],
            livesite:"https://spacelabz.co",
            githublink:"https://github.com/bytegen-dev",
            imagesrc:"spacelabz",
        },
        {
            type: "web3",
            typex: "Cardano",
            name:"LWH",
            description:"Levvywifhat",
            techused:[
                {name: "Next.js"},
                {name: "Typescript"},
                {name: "cardano"},
            ],
            livesite:"https://levvywifhat.com",
            githublink:"https://github.com/bytegen-dev",
            imagesrc:"lwh",
        },
    ]

    const projectsAll = projectsAllX.reverse()

    const [projectVisible, setProjectVisible] = React.useState(projectsAll)

    const [activeFilter, setActiveFilter] = React.useState("all")

    const [projectAnim, setProjectAnim] = React.useState(false)

    function showUIUX(){
        setProjectAnim(true)

        setTimeout(
            function(){
                setProjectVisible(()=>{
                    const newState = projectsAll.filter((project)=>{
                        return project.type === "uiux"
                    })
                    return newState
                })
                setProjectAnim(false)
            }, 500
        )
        setActiveFilter("uiux")
    }
    
    // function showHtmlCss(){
    //     setProjectAnim(true)
    
    //     setTimeout(
    //         function(){
    //             setProjectVisible(()=>{
    //                 const newState = projectsAll.filter((project)=>{
    //                     return project.type === "htmlcss"
    //                 })
    //                 return newState
    //             })
    //             setProjectAnim(false)
    //         }, 500
    //     )
    //     setActiveFilter("htmlcss")
        
    // }
    
    // function showNode(){
    //     setProjectAnim(true)
    
    //     setTimeout(
    //         function(){
    //             setProjectVisible(()=>{
    //                 const newState = projectsAll.filter((project)=>{
    //                     return project.type === "node"
    //                 })
    //                 return newState
    //             })
    //             setProjectAnim(false)
    //         }, 500
    //     )
    //     setActiveFilter("node")
    // }
    
    function showReact(){
        setProjectAnim(true) 
    
        setTimeout(
            function(){
                setProjectVisible(()=>{
                    const newState = projectsAll.filter((project)=>{
                        return project.type === "react"
                    })
                    return newState
                })
                setProjectAnim(false)
            }, 500
        )
        setActiveFilter("react")
    }

    function showWeb3(){
        setProjectAnim(true)
    
        setTimeout(
            function(){
                setProjectVisible(()=>{
                    const newState = projectsAll.filter((project)=>{
                        return project.type === "web3"
                    })
                    return newState
                })
                setProjectAnim(false)
            }, 500
        )
        setActiveFilter("web3")
    }
    
    function showAll(){
        setProjectAnim(true)
    
        setTimeout(
            function(){
                setProjectVisible(projectsAll)
                setProjectAnim(false)
            }, 500
        )
        setActiveFilter("all")
    }

    React.useEffect(
        function(){
            const projectHolder = document.getElementById("projects")

            projectHolder.scrollTo({
                top: 0,
                behavior: "smooth"
            })
        }, [projectAnim]
    )

    const projectsReversed = projectVisible

    const projectEl = projectsReversed.map((project)=>{
        const projectTech = project.techused.map((tech)=>{
            return(
                <div className="tech" key={tech.name}>
                    {tech.name}
                </div>
            )
        })

        return(
            <div className="project" key={project.name + Math.ceil(Math.random()*100)}>
                <div className="project--bg" style={{
                    backgroundImage: `url(./img/${project.imagesrc}.png)`,
                }}>
                    {/* <img src={`img/${project.imagesrc}.png`} alt={project.name} /> */}
                </div>
                <div className="site-info">
                    <div className="live-links">
                        <a href={project.livesite} target="_blank" rel="noreferrer"><i className="fa fa-arrow-up-right-from-square" /></a>
                        <a href={project.githublink} target="_blank" rel="noreferrer"><i className="fa-brands fa-github" /></a>
                    </div>
                    <div>
                        {project.description}
                    </div>

                    <div>
                        {projectTech}
                    </div>
                </div>

                <div className="absolute">
                    <span>{project.name}</span>
                </div>
                <div className="typex">
                    {project.typex}
                </div>
            </div>
        )
    })



    return(
        <div id="portfolio" className={props.className} onTouchStart={touchStarted}>
            <div className="heading">
                <h1>{props.isFrench?"Portefeuille":"Portfolio"}</h1>
                <div className="filter-bar">
                    <div onClick={showReact} className={activeFilter === "react"?"filter active":"filter"}>REACT</div>
                    <div onClick={showWeb3} className={activeFilter === "web3"?"filter active":"filter"}>WEB3</div>
                    <div onClick={showUIUX} className={activeFilter === "uiux"?"filter active":"filter"}>UI/UX</div>
                    {/* <div onClick={showNode} className={activeFilter === "node"?"filter active":"filter"}>NODE JS</div> */}
                    <div onClick={showAll} className={activeFilter === "all"?"filter active":"filter"}>{props.isFrench?"TOUS":"ALL"}</div>
                </div>
            </div>
            <div id="projects" className={projectAnim ?"projects hide":"projects"}>
                {projectEl}

                {projectEl.length < 1 && <div className="empty">
                    <i className="fa fa-heart-crack fa-beat-fade" />    
                </div>}
            </div>
        </div>
    )
}
